.container-fluid{
	height: calc(100% - 50px);
}

.ol-map {
    position: fixed;
    top: 85px;
    bottom: 50px;
    left: 0;
    right: 0;
}

.ol-map-admin {
    /* min-height: 500px;
    height: 500px;
    width: 100%; */
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.ol-control {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 4px;
    padding: 2px;
}

.ol-full-screen {
    top: .5em;
    right: .5em;
}

.ol-mouse-position-div {
    position: absolute;
    top: unset;
    right: unset;
    bottom: -1.25em;
    margin: auto;
    right: 50%;
    transform: translate(-50%, -50%);
    width: fit-content;
    z-index: 100;
    display: inline-flex;

}

.ol-mouse-position {
    position: absolute;
    width: 16em;
    top: 0;
    right: unset;
    font-size: x-small;
    height: calc(1.5em + 0.75rem + 1px);
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    color: #5c6873;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e4e7ea;
    border-radius: 0.25rem;
}

.ol-overviewmap {
    left: 0.5em;
    bottom: 2.5em;
}

.ol-layerswitcher .layerswitcher-progress{
    width: 180px;
}

.ol-layerswitcher .panel li label {
    max-width: 15em;
}

.ol-layerswitcher .panel li {
    max-width: 15em;
}