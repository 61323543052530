.ol-layerswitcher .layerswitcher-opacity {
  display: none;
}

.popup-info {
  z-index: 200;
}

.popup-info table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.popup-info td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.popup-info p {
  background-color: #2f353a;
}

.initial-map-zoom {
  pointer-events: auto;
  position: absolute;
  left: 0.5em;
  top: 3.5em;
}

.ol-search {
  top: 0;
}

.ol-scale-step-text {
  position: absolute;
  bottom: -5px;
  font-size: 12px;
  z-index: 11;
  color: #fff;
  text-shadow: unset
}

.ol-scale-singlebar {
  position: relative;
  height: 6px;
  z-index: 9;
  box-sizing: border-box;
  border: 1px solid black;
}

.ol-scale-step-marker {
  width: 1px;
  height: 15px;
  background-color: unset;
  float: right;
  z-index: 10;
}